<template>
  <div id="UserList">
    <title-nav :title="'수강권 지급 현황'" :nav="'회원관리 / 수강권 지급 현황'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <search :isDate="true" @search="item => setCondition(item)"></search>
        </div>
        <div class="card-body p-0">
          <b-table
            striped
            hover
            bordered
            class="table-sm text-sm-center"
            :borderless="borderless"
            :dark="dark"
            :fixed="fixed"
            :no-border-collapse="noCollapse"
            :items="items"
            :fields="fields"
            :head-variant="headVariant"
            :busy="isBusy"
            show-empty
          >
            <template v-slot:cell(ev_why_cd)="data">
              <span>{{ convertRewardCode(data.value) }}</span>
            </template>
            <template v-slot:cell(reg_dt)="data">
              <span>{{ data.value | dateFilter }}</span>
            </template>
            <template v-slot:empty>
              <h4>데이터가 없습니다.</h4>
            </template>
          </b-table>
        </div>
        <div class="card-footer clearfix">
          <b-pagination
            v-model="page"
            :total-rows="records"
            :per-page="rowPerPage"
            @change="setPage"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </b-container>
    <template>
      <b-modal id="model-noti" hide-footer title="보상사유 상세" static>
        <div class=" text-center">
          <h3>보상사유 상세</h3>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import Search from '../util/Search'
import { getVoucherList } from '../../api/user'
import { getRewardType } from '../../api/code'

export default {
  name: 'UserVoucher',
  components: {
    Search
  },
  data() {
    return {
      page: 1,
      records: 0,
      rowPerPage: 20,
      modalPage: 1,
      item: [],
      items: [],
      striped: true,
      bordered: true,
      borderless: false,
      small: true,
      hover: true,
      dark: false,
      fixed: false,
      tableVariant: '',
      noCollapse: false,
      headVariant: 'dark',
      REWARD_CODE: [],
      isBusy: false,
      fields: [
        {
          key: 'user_sn',
          label: '회원번호',
          colType: 'span'
        },
        {
          key: 'user_name',
          label: '닉네임',
          colType: 'span'
        },
        {
          key: 'real_name',
          label: '이름',
          colType: 'span'
        },
        {
          key: 'email',
          label: '이메일 ',
          colType: 'span',

          tdClass: 'text-left'
        },
        {
          key: 'ev_why_cd',
          label: '보상사유',
          colType: 'span'
        },
        {
          key: 'content',
          label: '보상사유',
          colType: 'span'
        },
        {
          key: 'title',
          label: '지급 수강권',
          colType: 'span'
        },
        {
          key: 'reg_dt',
          label: '보상 일시',
          colType: 'span'
        },
        {
          key: 'admin_name',
          label: '운영자',
          colType: 'span'
        }
      ]
    }
  },
  methods: {
    openReason(sn) {
      console.log(`sn: ${sn}`)
    },
    async setCondition(search) {
      // nickname, email, user_sn
      this.page = 1
      const response = await getVoucherList(
        this.page,
        this.rowPerPage,
        search.key,
        search.value,
        search.start,
        search.end
      )
      if (response.code === '0000') {
        this.items = response.data.list
        this.records = response.data.totCnt
      } else {
        alert(response.message)
      }
    },
    setPage(num) {
      this.page = num
      this.init()
    },
    convertRewardCode(code) {
      var item = this.REWARD_CODE.find(item => item.cd_value == code)
      if (item) {
        return item.cd_name_kr
      } else {
        return `Not Mathcing(${code})`
      }
    },
    async initRewardCode() {
      const resp = await getRewardType()
      this.REWARD_CODE = resp
    },
    async init() {
      const response = await getVoucherList(this.page, this.rowPerPage, '', '')
      if (response.code === '0000') {
        this.items = response.data.list
        this.lastPage =
          Math.floor(response.data.totCnt / this.rowPerPage) +
          (response.data.totCnt % this.rowPerPage === 0 ? 0 : 1)
      } else {
        alert(response.message)
      }
    }
  },
  mounted() {
    this.initRewardCode()
    this.init()
  }
}
</script>
